export const fields = [
  {
    // Visible in table header and when matching columns.
    label: "Établissement",
    // This is the key used for this field when we call onSubmit.
    key: "companyName",
    // Allows for better automatic column matching. Optional.
    alternateMatches: [
      "établissement",
      "ETABLISSEMENT",
      "société",
      "Société",
      "magasin",
      "magasins",
      "Magasins",
      "Magasin",
      "MAGASIN",
    ],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Mc Donald Nice",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Le nom de l'établissement est obligatoire",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    label: "Nom du contact",
    key: "lastName",
    alternateMatches: ["contact", "CONTACT", "lastname", "nom"],
    fieldType: {
      type: "input",
    },
    example: "Dupont",
  },
  {
    label: "email",
    key: "email",
    alternateMatches: ["email", "mail", "MAIL", "e-mail"],
    fieldType: {
      type: "input",
    },
    example: "Dupont@justnow.app",
  },
  {
    label: "Téléphone",
    key: "phone",
    alternateMatches: ["phone", "TELEPHONE", "telephone", "téléphone"],
    fieldType: {
      type: "input",
    },
    example: "+33478852598",
  },
  {
    label: "Adresse",
    key: "address",
    alternateMatches: ["address", "adresse", "ADRESSE"],
    fieldType: {
      type: "input",
    },
    example: "5 rue des Champs-Élysée",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Name is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Website",
    key: "website",
    alternateMatches: [
      "site",
      "website",
      "Website",
      "Site",
      "Site internet",
      "internet",
      "SITE INTERNET",
    ],
    fieldType: {
      type: "input",
    },
    example: "google.fr",
  },
  {
    label: "Description",
    key: "detail",
    alternateMatches: ["description", "desc", "DESCRIPTION"],
    fieldType: {
      type: "input",
    },
    example: "Super établissement...",
  },
  {
    label: "Latitude",
    key: "latitude",
    alternateMatches: ["latitude", "lat", "LATITUDE"],
    fieldType: {
      type: "input",
    },
    example: "4,8512",
  },
  {
    label: "Longitude",
    key: "longitude",
    alternateMatches: ["longitude", "long", "LONGITUDE"],
    fieldType: {
      type: "input",
    },
    example: "4,5564",
  },
  {
    label: "Ville",
    key: "city",
    alternateMatches: ["ville", "VILLE", "city", "town"],
    fieldType: {
      type: "input",
    },
    example: "Paris",
  },
  {
    label: "CP",
    key: "cp",
    alternateMatches: ["cp", "code postal", "Cp"],
    fieldType: {
      type: "input",
    },
    example: "75200",
  },
  {
    label: "Pays",
    key: "country",
    alternateMatches: ["pays", "PAYS"],
    fieldType: {
      type: "input",
    },
    example: "France",
  },
  {
    label: "Horaires",
    key: "workingHours",
    alternateMatches: ["HORAIRES", "HORAIRE", "Horaire", "horaire", "horaires"],
    fieldType: {
      type: "input",
    },
    example: "[[10:00][19:00],[],[],[],[],[],[]]",
  },
]
