import React, { useContext, useEffect, useRef, useState } from "react"
import { ReactSpreadsheetImport } from "react-spreadsheet-import"
import Button from "../../components/CustomButtons/Button"
import { ImportExport } from "@material-ui/icons"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import CardHeader from "../../components/Card/CardHeader"
import CardText from "../../components/Card/CardText"
import CardBody from "../../components/Card/CardBody"
import { Card } from "react-bootstrap"

// Style
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import { makeStyles } from "@material-ui/core/styles"
import buttonStyles from "../../assets/jss/material-dashboard-pro-react/views/buttonsStyle"
import "./importWizard.css"

// Private Component
import authContext from "../../context/AuthContext"
import { fields } from "./field"
import { translations } from "./traductions"
import { massCompanyCreation } from "../../api/companyAction"
import { GridLoader } from "react-spinners"

// Local constant
const label = {
  formTitle: "Importer mes établissements",
  import: "Importer",
  company: "société*",
  companies: "Choisir un ou plusieurs établissements",
  name: "Nom du contact",
  description: "description",
}

const useStyles = makeStyles(styles)
const useButtonStyles = makeStyles(buttonStyles)
export const ImportWizard = () => {
  const { mpId } = useContext(authContext)
  const [isOpen, setIsOpen] = useState(false)
  const [finalStatus, setFinalStatus] = useState({ ok: [], ko: [] })
  const [details, setDetails] = useState({ ok: [], ko: [] })
  const [loading, setLoading] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onSubmit = (a) => {
    setLoading(true)
    massCompanyCreation(a.validData, mpId)
      .then((r) => {
        console.log("response : ", r)
        if (r.data.status == 1) {
          console.log("type of data : ", typeof r.data.data)
          let st = r.data.data
          setFinalStatus({ ok: st?.ok, ko: st?.ko })
          setLoading(false)
        } else {
          setLoading(false)
          alert(
            "Attention, quelque chose ne s'est pas bien passé.\n Contacter un administrateur."
          )
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log("error : ", e)
      })
    // else alert("Aucune donnée valide")
  }

  /**
   *
   * @param _status
   * @returns {string}
   */
  const summary = (_status) => {
    if (_status === "ok")
      if (finalStatus.ok.length === 1)
        return `${finalStatus.ok.length} établissement a été inséré avec succès.`
      else
        return `${finalStatus.ok.length} établissements ont été insérés avec succès.`
    else if (finalStatus.ko.length === 1)
      return `${finalStatus.ko.length} établissement n'a pas pu être inséré.`
    else return `${finalStatus.ko.length} établissements n'ont pas pu être insérés.`
  }

  const buttonClasses = useButtonStyles()
  const classes = useStyles()
  return (
    <div>
      {!loading ? (
        <GridContainer container spacing={1} direction="row" alignItems="center">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>{label.formTitle}</h4>
                </CardText>
              </CardHeader>

              <div style={{ marginTop: 20, color: "lightgray", marginLeft: 20 }}>
                <h1 style={{ textAlign: "center", marginBottom: 20 }}>
                  Attention l&lsquo;insertion peut être longue
                </h1>
                <ol>
                  <li>
                    Vous devez avoir un fichier comportant au moins les informations
                    suivantes:
                  </li>
                  <ul style={{ marginLeft: 20 }}>
                    <li>Nom de l établissement</li>
                    <li>Email du responsable de l établissement</li>
                  </ul>
                  <li style={{ marginTop: 20 }}>
                    Certaines données facultatives peuvent faciliter la configuration
                    de votre compte &nbsp;
                    <a href="/faq#menu-5" rel="noopener noreferrer" target="_blank">
                      (voir la doc.)
                    </a>
                    <ul style={{ marginLeft: 20 }}>
                      <li>Adresse, Ville et CP</li>
                      <li>Cordonnées (Latitude & Longitude)</li>
                      <li>Site internet</li>
                      <li>Description</li>
                    </ul>
                  </li>
                </ol>
              </div>

              {/*<CardBody>*/}
              <form style={{ width: "100%", textAlign: "center", marginTop: 100 }}>
                <Button color="success" round onClick={handleOpen}>
                  <ImportExport className={buttonClasses.icons} /> {label.import}
                </Button>
                {/*<Button*/}
                {/*  color="primary"*/}
                {/*  round*/}
                {/*  onClick={() => {*/}
                {/*    console.log(finalStatus, finalStatus.ok.length)*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <ImportExport className={buttonClasses.icons} /> {label.import}*/}
                {/*</Button>*/}
                <ReactSpreadsheetImport
                  isOpen={isOpen}
                  onClose={onClose}
                  onSubmit={(a) => onSubmit(a)}
                  fields={fields}
                  translations={translations}
                />
              </form>
              {/*</CardBody>*/}
            </Card>
          </GridItem>
          <GridItem id="summary" xs={12}>
            <p>{finalStatus.ok.length > 0 ? summary("ok") : ""}</p>
            <p>{finalStatus.ko.length > 0 ? summary("ko") : ""}</p>

            {finalStatus.ok.length > 0 && (
              <p>Les établissements suivants ont été insérés:</p>
            )}
            <ul>
              {finalStatus.ok.map((item, index) => (
                <li key={index}>{item.cname}</li>
              ))}
            </ul>
            {finalStatus.ko.length > 0 && (
              <p>Les établissements suivants n ont pas été insérés:</p>
            )}
            <ul>
              {finalStatus.ko.map((item, index) => (
                <li key={index}>
                  {item.cname} ({item.email}) cause : {item.error}
                </li>
              ))}
            </ul>
          </GridItem>
        </GridContainer>
      ) : (
        <div>
          <GridLoader
            color="#3f4850"
            loading={loading}
            size={50}
            css={`
              position: absolute;
              display: block;
              z-index: 999;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          />
        </div>
      )}
    </div>
  )
}
