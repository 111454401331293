/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from "react"
import isEmail from "validator/lib/isEmail"
import isURL from "validator/lib/isURL"
import isMobilePhone from "validator/es/lib/isMobilePhone"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import { GridLoader } from "react-spinners"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"

// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js"
import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js"

import ImageUpload from "../../components/CustomUpload/ImageUpload"
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle"
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"
import Favorite from "@material-ui/icons/Favorite"

import {
  AlternateEmail,
  Apartment,
  InfoRounded,
  Lock,
  People,
  Phone,
  TitleRounded,
  Web,
} from "@material-ui/icons"

// Private Component
import JAlert from "../../components/smallComponent/jAlert"
import WorkingHours from "../../utils/hours/workingHours"

// Context & API
import authContext from "../../context/AuthContext"
import {
  getBusinessType,
  getMultiproAccountData,
  setMultiproAccountData,
} from "../../api/companyAction"
import config from "../../config"
import {
  getMPNameFromLocalStorage,
  setMPNameFromLocalStorage,
} from "../../services/AuthApi"
import { Tooltip } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Divider from "@material-ui/core/Divider"
import ChangePasswordModal from "../../components/Button/ChangePasswordModal"

const localStyles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
}

const disabledStyle = {
  disabledBack: {
    backgroundColor: "red",
  },
}

const useDisabledStyle = makeStyles(disabledStyle)
const useButtonStyles = makeStyles(buttonStyles)
const useLocalStyle = makeStyles(localStyles)
const useStyles = makeStyles(styles)
const useStylesExtended = makeStyles(stylesExtended)

const BASE_URL = config.BASE_URL

const days = [
  { key: "mon", name: "Lundi" },
  { key: "tue", name: "Mardi" },
  { key: "wed", name: "Mercredi" },
  { key: "thu", name: "Jeudi" },
  { key: "fri", name: "Vendredi" },
  { key: "sat", name: "Samedi" },
  { key: "sun", name: "Dimanche" },
]

const label = {
  formTitle: "Mon compte",
  visuel: "Visuel",
  company: "société*",
  companies: "Choisir un ou plusieurs établissements",
  name: "Nom du contact",
  description: "description",
  suspended: "Suspendu ?",
  image: "Image de profil",
  openingHours: "Horaire d'ouverture",
  site: "site internet",
  phone: "téléphone",
  save: "Enregistrer",
  latitude: "Latitude",
  longitude: "Longitude",
  address: "Adresse",
  email: "Email*",
  defaultSubCompanyPassword: "Mot de passe par défaut pour vos établissements",
  emailingEventOn: "Envoi d'email aux établissements désactivé",
  emailingEventOff: "Envoi d'email aux établissements activé",
  moreParameters: "Paramètres supplémentaires",
  moreParametersExplanation:
    "Vous pouvez définir des paramètres par defaut pour vos établissements",
}

const ALERT_COLOR = "coral"

export function Account(props) {
  const { mpId, mpEmail, mpRole } = useContext(authContext)
  const [mpName, setMpName] = useState(getMPNameFromLocalStorage())

  // const [checked, setChecked] = useState([24, 22])
  // const [selectedEnabled, setSelectedEnabled] = useState("b")
  // const [selectedValue, setSelectedValue] = useState(null)
  // const [simpleSelect, setSimpleSelect] = useState("")
  // const [actif, setActif] = useState(false)
  // const [confirmMessage, setConfirmMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("test error message")
  const [errorMessageShowing, setErrorMessageShowing] = useState(false)
  const [businessType, setBusinessType] = useState(undefined)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [base64Image, setBase64Image] = useState(undefined)
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    defaultSubCompanyPassword: "",
    phone: "",
    cover: "",
    demo: false,
    businessLimit: 0,
    businessType: 0,
    emailSubCompanyEvent: true,
    defaultSubCompanyWorkingHours: [],
  })
  const [loading, setLoading] = useState(true)
  const [imageCompany, setImageCompany] = useState(undefined)
  const [imagesData, setImagesData] = useState()
  const [info, setInfo] = useState({
    name: "green",
    email: "green",
    password: "green",
    confirm_password: "green",
    defaultSubCompanyPassword: "green",
    emailSubCompanyEvent: "green",
    phone: "green",
    cover: "green",
    businessLimit: "grey",
    businessType: "green",
  })
  const [check, setCheck] = useState({
    check_companyMissing: false,
    check_emailMissing: false,
    check_lastNameMissing: false,
    check_coordsMissing: false,
    check_phoneMissing: false,
    check_emailInputChange: true,
    check_textInputChange: false,
    secureTextEntry: true,
    confirm_secureTextEntry: true,
  })
  const [showAlert, setShowAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState("success")
  const [alertMessageBox, setAlertMessageBox] = useState(
    "Bravo, vos modifications ont été enregistrées."
  )

  /**
   * Note : Get USER DATA
   */
  useEffect(() => {
    if (mpId && mpEmail)
      getMultiproAccountData(mpId, mpEmail)
        .then((response) => {
          if (typeof response.data !== "undefined") {
            if (response.status === 200) {
              // console.log("response mpid", response.data)
              let x = response.data.data
              let u = response.data.user

              setData({
                ...data,
                name: x.name,
                email: u.email,
                password: "",
                confirm_password: "",
                defaultSubCompanyPassword: x?.defaultSubCompanyPassword,
                emailSubCompanyEvent: x?.emailSubCompanyEvent ?? true,
                phone: x.phone || "",
                detail: x.detail,
                cover: x.iscover,
                demo: x.demo,
                businessLimit: 0,
                defaultSubCompanyWorkingHours: x.defaultSubCompanyWorkingHours
                  ? x?.defaultSubCompanyWorkingHours
                  : [],
              })
              setLoading(false)
            }
          }
        })
        .catch((e) => {
          console.log("catch", e)
          /** Manage problem with token
           * - Expired JWT Token
           * - Invalid JWT Token
           */
        })
    else setLoading(false)
  }, [mpEmail, mpId])

  /**
   * Note : Fill The Business Type Selector
   */
  useEffect(() => {
    getBusinessType()
      .then((response) => {
        if (typeof response.data !== "undefined") {
          if (response.status >= 200 && response.status <= 299) {
            let st = response.data.map(({ id, name, icon, ...rest }) => ({
              key: id,
              label: name,
              icon: icon,
            }))
            setBusinessType(st)
          }
        }
      })
      .catch((e) => console.log(e))
  }, [])

  /**
   * Temporary set default businesstype
   */
  useEffect(() => {
    if (businessType) {
      setData({ ...data, businessType: 0 })
    }
  }, [businessType])

  /**
   * Note : reloading image each time...
   */
  useEffect(() => {
    if (mpId)
      setImageCompany(
        BASE_URL + "img/multipro/cover-" + mpId + ".jpg?date=" + new Date().getTime()
      )
  }, [mpId])

  /**
   * Note: management of simple input
   * @param _text
   * @param _fromWich
   */
  const handleInputChange = (_text, _fromWich) => {
    setData({ ...data, [_fromWich]: _text })

    switch (_fromWich) {
      case "name":
        setInfo({ ...info, [_fromWich]: _text ? "#495057" : "coral" })
        break
      case "email":
        if (isEmail(_text)) {
          setInfo({ ...info, [_fromWich]: "green" })
        } else {
          setInfo({ ...info, [_fromWich]: "coral" })
        }
        break
      case "phone":
        if (isMobilePhone(_text)) {
          setInfo({ ...info, [_fromWich]: "green" })
        } else {
          setInfo({ ...info, [_fromWich]: "coral" })
        }
        break
    }
  }

  /**
   * ###############################################################################
   * Saving the data                      handleRegister
   * ###############################################################################
   * */
  const handleRegister = () => {
    let showAlert = false
    setLoading(true)

    if (data.name.length === 0) {
      setInfo((prevState) => {
        return { ...prevState, companyName: ALERT_COLOR }
      })
      showAlert = true
    }
    if (data.email.length === 0) {
      setInfo((prevState) => {
        return { ...prevState, email: ALERT_COLOR }
      })
      showAlert = true
    }
    if (showAlert === false) {
      // Updating text data
      if (mpId) {
        setMultiproAccountData(data, mpId, base64Image)
          .then((response) => {
            console.log("response", response)
            setMPNameFromLocalStorage(data.name)
            setMpName(data.name)
            setLoading(false)
            setShowAlert(true)
          })
          .catch((e) => {
            console.log(e)
            alert("Une erreur est survenue")
            setLoading(false)
          })
        // Updating image if necessary
        // if (isNewImage) {
        //   uploadImageCompany(cid, imagesData)
        //     .then((response) => {
        //       setLoading(false)
        //     })
        //     .catch((e) => {
        //       console.log(e)
        //       alert("Update image failure")
        //       setLoading(false)
        //     })
        // }}
      }
    } else {
      // Information about the errors or missing data
      setErrorMessage("Veuillez vérifier vos données")
      setErrorMessageShowing(true)
      setTimeout(() => {
        setErrorMessageShowing(false)
        setLoading(false)
      }, 2000)
    }
  }

  /**
   * Note : Alert manager
   */
  const showInfo = () => {
    setShowAlert(!showAlert)
  }

  /**
   * Note Get information from child component Working Hours
   * @param _data
   */
  const getPlanning = (_data) => {
    // console.log("backfire", _data.toString())
    setData({ ...data, ["defaultSubCompanyWorkingHours"]: _data })
  }

  // Style construction (multi theme options...)
  const buttonClasses = useButtonStyles()
  const classes = useStyles()
  const classesExtended = useStylesExtended()
  const classesLocal = useLocalStyle()
  const disabled = useDisabledStyle()

  return (
    <div>
      {!loading ? (
        <GridContainer>
          {/*Large Form*/}
          <JAlert
            type={typeAlert}
            show={showAlert}
            localStatus={showInfo}
            titleText={alertMessageBox}
            subTitleText={
              typeAlert === "success " ? "...et elles sont déjà actives." : ""
            }
          />
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>
                    {mpName ? mpName : "Nom de votre Société"}
                  </h4>
                  {data && data?.demo && (
                    <p style={{ fontStyle: "italic" }}>
                      Vous êtes actuellement en mode 'démo'
                    </p>
                  )}
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                  >
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={label.company}
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Apartment style={{ color: info.companyName }} />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.name,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "name")
                          },
                        }}
                      />
                      {/*<CustomInput*/}
                      {/*  labelText={label.name}*/}
                      {/*  id="lastname"*/}
                      {/*  formControlProps={{*/}
                      {/*    fullWidth: true,*/}
                      {/*  }}*/}
                      {/*  inputProps={{*/}
                      {/*    endAdornment: (*/}
                      {/*      <InputAdornment position="end">*/}
                      {/*        <People style={{ color: info.lastName }} />*/}
                      {/*      </InputAdornment>*/}
                      {/*    ),*/}
                      {/*    type: "text",*/}
                      {/*    value: data?.lastName,*/}
                      {/*    onChange: (e) => {*/}
                      {/*      handleInputChange(e.target.value, "lastName")*/}
                      {/*    },*/}
                      {/*  }}*/}
                      {/*/>*/}
                      {/** ################################################ **/}
                      {/** #######    defaultSubCompanyPassword     ####### **/}
                      {/** ################################################ **/}
                      <ChangePasswordModal />
                      {/** ################################################ **/}
                      {/** ####################     EMAIL     ############# **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText={label.email}
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AlternateEmail style={{ color: info.email }} />
                            </InputAdornment>
                          ),
                          type: "email",
                          value: data?.email,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "email")
                          },
                        }}
                      />
                      {/*<CustomInput*/}
                      {/*  labelText="Mot de passe"*/}
                      {/*  id="password"*/}
                      {/*  formControlProps={{*/}
                      {/*    fullWidth: true,*/}
                      {/*  }}*/}
                      {/*  inputProps={{*/}
                      {/*    type: "password",*/}
                      {/*    autoComplete: "off",*/}
                      {/*  }}*/}
                      {/*/>*/}
                      {/** ################################################ **/}
                      {/** ####################     PHONE     ############# **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText="Téléphone"
                        id="phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Phone />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.phone,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "phone")
                          },
                        }}
                      />
                      <div style={{ textAlign: "center", marginTop: 40 }}>
                        <h4 className={classes.cardTitle}>{label.moreParameters}</h4>
                        <h6
                          style={{
                            marginBottom: "20px",
                            fontSize: "0.8rem",
                          }}
                        >
                          {label.moreParametersExplanation}
                        </h6>
                      </div>

                      {/** ################################################ **/}
                      {/** #######    defaultSubCompanyPassword     ####### **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText={
                          <>
                            <>{label.defaultSubCompanyPassword}</>
                            <Tooltip
                              title={
                                <p style={{ fontSize: 12 }}>
                                  Ce mot de passe par défault sera envoyé à vos
                                  responsables d'établissements
                                  <br />
                                  Il leur sera conseillé de le changer dès que
                                  possible.
                                </p>
                              }
                            >
                              <IconButton style={{ height: 18 }}>
                                <InfoRounded style={{ color: "#42988d" }} />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        id="defaultSubCompanyPassword"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Lock style={{ color: info.companyName }} />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.defaultSubCompanyPassword,
                          onChange: (e) => {
                            handleInputChange(
                              e.target.value,
                              "defaultSubCompanyPassword"
                            )
                          },
                        }}
                      />
                      {/** ################################################ **/}
                      {/** #################    emailingEvent    ########## **/}
                      {/** ################################################ **/}
                      <div className={classesExtended.block}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="emailingEvent"
                              checked={!!Number(data.emailSubCompanyEvent)}
                              onChange={(event) => {
                                // setIsFree(event.target.checked)
                                handleInputChange(
                                  event.target.checked,
                                  "emailSubCompanyEvent"
                                )
                              }}
                              //value={isFree}
                              classes={{
                                switchBase: classesExtended.switchBase,
                                checked: classesExtended.switchChecked,
                                thumb: classesExtended.switchIcon,
                                track: classesExtended.switchBar,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={
                            data.emailSubCompanyEvent
                              ? label.emailingEventOff
                              : label.emailingEventOn
                          }
                        />
                      </div>
                    </GridItem>
                    {/** ################################################ **/}
                    {/** ####################  VISUEL       ############# **/}
                    {/** ################################################ **/}
                    {/*Suppression de l'affichage de l'iamge sur demand jlaffont*/}

                    {/*<GridItem xs={12} sm={4}>*/}
                    {/*  <GridContainer*/}
                    {/*    container*/}
                    {/*    spacing={1}*/}
                    {/*    direction="column"*/}
                    {/*    alignItems="center"*/}
                    {/*  >*/}
                    {/*    <legend style={{ textAlign: "center" }}>*/}
                    {/*      {label.image}*/}
                    {/*    </legend>*/}
                    {/*    <ImageUpload*/}
                    {/*      b64={(x) => {*/}
                    {/*        setBase64Image(x)*/}
                    {/*      }}*/}
                    {/*      modification={true}*/}
                    {/*      avatar*/}
                    {/*      url={imageCompany}*/}
                    {/*      addButtonProps={{*/}
                    {/*        color: "rose",*/}
                    {/*        round: true,*/}
                    {/*      }}*/}
                    {/*      changeButtonProps={{*/}
                    {/*        color: "rose",*/}
                    {/*        round: true,*/}
                    {/*      }}*/}
                    {/*      removeButtonProps={{*/}
                    {/*        color: "danger",*/}
                    {/*        round: true,*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </GridContainer>*/}
                    {/*</GridItem>*/}
                    {/*<Divider />*/}
                    {/** ################################################ **/}
                    {/** #################    Opening Hours    ########## **/}
                    {/** ################################################ **/}
                    <GridContainer
                      container
                      spacing={2}
                      direction="column"
                      alignItems="center"
                      style={{ marginTop: 40 }}
                    >
                      <GridItem xs={12} sm={12}>
                        <legend style={{ textAlign: "center" }}>
                          {label.openingHours}
                        </legend>
                        <div className={classesExtended.block}>
                          <WorkingHours
                            fieldName={"location[working_hours]"}
                            dataStr={data.defaultSubCompanyWorkingHours}
                            backFire={(a) => getPlanning(a)}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>

                    <GridContainer
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                    >
                      <GridItem>
                        <Button
                          color="success"
                          round
                          onClick={() => {
                            handleRegister()
                          }}
                        >
                          <Favorite className={buttonClasses.icons} /> Enregistrer
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <div className={disabled.disabledBack}>
          <GridLoader
            color="#3f4850"
            loading={loading}
            size={50}
            css={`
              position: absolute;
              display: block;
              z-index: 999;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          />
          <JAlert
            type="success"
            show={showAlert}
            localStatus={showInfo}
            titleText="Bravo, vos modifications ont été enregistrées."
            subTitleText="...et elles sont déjà actives."
          />
        </div>
      )}
    </div>
  )
}
