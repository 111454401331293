import React, { useContext, useEffect } from "react"
// react plugin for creating charts
import ChartistGraph from "react-chartist"
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Icon from "@material-ui/core/Icon"

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store"
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning"
import DateRange from "@material-ui/icons/DateRange"
import LocalOffer from "@material-ui/icons/LocalOffer"
import Update from "@material-ui/icons/Update"
import ArrowUpward from "@material-ui/icons/ArrowUpward"
import AccessTime from "@material-ui/icons/AccessTime"
import Refresh from "@material-ui/icons/Refresh"
import Edit from "@material-ui/icons/Edit"
import Place from "@material-ui/icons/Place"
import ArtTrack from "@material-ui/icons/ArtTrack"
import Language from "@material-ui/icons/Language"

// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Table from "components/Table/Table.js"
import Button from "components/CustomButtons/Button.js"
import Danger from "components/Typography/Danger.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardIcon from "components/Card/CardIcon.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts"

// Context
import authContext from "../../context/AuthContext"

// Private Components
import EventScroller from "../Flux/eventScroller"

// Styling
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js"

import priceImage1 from "assets/img/card-2.jpeg"
import priceImage2 from "assets/img/card-3.jpeg"
import priceImage3 from "assets/img/card-1.jpeg"
import SummaryCharts from "../Charts/SummaryCharts"
import Heading from "../../components/Heading/Heading"
import AccountStatSummary from "../AccountStatSummary"

const us_flag = require("assets/img/flags/US.png").default
const de_flag = require("assets/img/flags/DE.png").default
const au_flag = require("assets/img/flags/AU.png").default
const gb_flag = require("assets/img/flags/GB.png").default
const ro_flag = require("assets/img/flags/RO.png").default
const br_flag = require("assets/img/flags/BR.png").default
const fr_flag = require("assets/img/flags/FR.png").default
const it_flag = require("assets/img/flags/IT.png").default

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
}

const useStyles = makeStyles(styles)

export default function Dashboard() {
  const classes = useStyles()
  const { mpRole, mpId } = useContext(authContext)
  useEffect(() => {
    console.log("dashboard", mpRole, mpId)
  })

  return (
    <div>
      {/*<Heading textAlign="center" title="Mon tableau de bord" />*/}
      <GridContainer justify="center">
        <GridItem
          xs={12}
          sm={12}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <img src="https://justnow.app/logo.png" />
        </GridItem>
      </GridContainer>
      <AccountStatSummary />
      <SummaryCharts />

      <div style={{ marginTop: 50 }}>
        <Heading textAlign="center" title="Vos événements actifs" />
        <GridItem
          xs={12}
          sm={12}
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        ></GridItem>
        <EventScroller />
      </div>

      {/*<GridContainer>*/}
      {/*  <GridItem xs={12}>*/}
      {/*    <Card>*/}
      {/*      <CardHeader color="success" icon>*/}
      {/*        <CardIcon color="success">*/}
      {/*          <Language />*/}
      {/*        </CardIcon>*/}
      {/*        <h4 className={classes.cardIconTitle}>*/}
      {/*          Répartitions des événements par villes*/}
      {/*        </h4>*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <GridContainer justify="space-between">*/}
      {/*          <GridItem xs={12} sm={12} md={5}>*/}
      {/*            <Table*/}
      {/*              tableData={[*/}
      {/*                [*/}
      {/*                  <img src={fr_flag} alt="us_flag" key={"flag"} />,*/}
      {/*                  "PARIS",*/}
      {/*                  "2.920",*/}
      {/*                  "53.23%",*/}
      {/*                ],*/}
      {/*                [*/}
      {/*                  <img src={fr_flag} alt="us_flag" key={"flag"} />,*/}
      {/*                  "Lyon",*/}
      {/*                  "1.300",*/}
      {/*                  "20.43%",*/}
      {/*                ],*/}
      {/*                [*/}
      {/*                  <img src={fr_flag} alt="us_flag" key={"flag"} />,*/}
      {/*                  "Nice",*/}
      {/*                  "760",*/}
      {/*                  "10.35%",*/}
      {/*                ],*/}
      {/*                [*/}
      {/*                  <img src={fr_flag} alt="us_flag" key={"flag"} />,*/}
      {/*                  "Menton",*/}
      {/*                  "690",*/}
      {/*                  "7.87%",*/}
      {/*                ],*/}
      {/*                [*/}
      {/*                  <img src={it_flag} alt="us_flag" key={"flag"} />,*/}
      {/*                  "San Remo",*/}
      {/*                  "600",*/}
      {/*                  "5.94%",*/}
      {/*                ],*/}
      {/*                [*/}
      {/*                  <img src={it_flag} alt="us_flag" key={"flag"} />,*/}
      {/*                  "Gênes",*/}
      {/*                  "550",*/}
      {/*                  "4.34%",*/}
      {/*                ],*/}
      {/*              ]}*/}
      {/*            />*/}
      {/*          </GridItem>*/}
      {/*          <GridItem xs={12} sm={12} md={6}>*/}
      {/*            <VectorMap*/}
      {/*              map={"fr_mill"}*/}
      {/*              backgroundColor="transparent"*/}
      {/*              zoomOnScroll={false}*/}
      {/*              containerStyle={{*/}
      {/*                width: "100%",*/}
      {/*                height: "280px",*/}
      {/*              }}*/}
      {/*              containerClassName="map"*/}
      {/*              regionStyle={{*/}
      {/*                initial: {*/}
      {/*                  fill: "#e4e4e4",*/}
      {/*                  "fill-opacity": 0.9,*/}
      {/*                  stroke: "none",*/}
      {/*                  "stroke-width": 0,*/}
      {/*                  "stroke-opacity": 0,*/}
      {/*                },*/}
      {/*              }}*/}
      {/*              series={{*/}
      {/*                regions: [*/}
      {/*                  {*/}
      {/*                    values: mapData,*/}
      {/*                    scale: ["#AAAAAA", "#444444"],*/}
      {/*                    normalizeFunction: "polynomial",*/}
      {/*                  },*/}
      {/*                ],*/}
      {/*              }}*/}
      {/*            />*/}
      {/*          </GridItem>*/}
      {/*        </GridContainer>*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*</GridContainer>*/}

      {/*<GridContainer>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart className={classes.cardHover}>*/}
      {/*      <CardHeader color="info" className={classes.cardHeaderHover}>*/}
      {/*        <ChartistGraph*/}
      {/*          className="ct-chart-white-colors"*/}
      {/*          data={dailySalesChart.data}*/}
      {/*          type="Line"*/}
      {/*          options={dailySalesChart.options}*/}
      {/*          listener={dailySalesChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <div className={classes.cardHoverUnder}>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Refresh"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button simple color="info" justIcon>*/}
      {/*              <Refresh className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Change Date"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="transparent" simple justIcon>*/}
      {/*              <Edit className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*        </div>*/}
      {/*        <h4 className={classes.cardTitle}>Événements</h4>*/}
      {/*        <p className={classes.cardCategory}>*/}
      {/*          <span className={classes.successText}>*/}
      {/*            <ArrowUpward className={classes.upArrowCardCategory} /> 55%*/}
      {/*          </span>{" "}*/}
      {/*          increase in today sales.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> updated 4 minutes ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart className={classes.cardHover}>*/}
      {/*      <CardHeader color="warning" className={classes.cardHeaderHover}>*/}
      {/*        <ChartistGraph*/}
      {/*          className="ct-chart-white-colors"*/}
      {/*          data={emailsSubscriptionChart.data}*/}
      {/*          type="Bar"*/}
      {/*          options={emailsSubscriptionChart.options}*/}
      {/*          responsiveOptions={emailsSubscriptionChart.responsiveOptions}*/}
      {/*          listener={emailsSubscriptionChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <div className={classes.cardHoverUnder}>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Refresh"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button simple color="info" justIcon>*/}
      {/*              <Refresh className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Change Date"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="transparent" simple justIcon>*/}
      {/*              <Edit className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*        </div>*/}
      {/*        <h4 className={classes.cardTitle}>Email Subscriptions</h4>*/}
      {/*        <p className={classes.cardCategory}>Last Campaign Performance</p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> campaign sent 2 days ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart className={classes.cardHover}>*/}
      {/*      <CardHeader color="danger" className={classes.cardHeaderHover}>*/}
      {/*        <ChartistGraph*/}
      {/*          className="ct-chart-white-colors"*/}
      {/*          data={completedTasksChart.data}*/}
      {/*          type="Line"*/}
      {/*          options={completedTasksChart.options}*/}
      {/*          listener={completedTasksChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <div className={classes.cardHoverUnder}>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Refresh"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button simple color="info" justIcon>*/}
      {/*              <Refresh className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Change Date"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="transparent" simple justIcon>*/}
      {/*              <Edit className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*        </div>*/}
      {/*        <h4 className={classes.cardTitle}>Completed Tasks</h4>*/}
      {/*        <p className={classes.cardCategory}>Last Campaign Performance</p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> campaign sent 2 days ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*</GridContainer>*/}

      {/*<h3>Aperçu des derniers événements</h3>*/}
      {/*<br />*/}
      {/*<GridContainer>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card product className={classes.cardHover}>*/}
      {/*      <CardHeader image className={classes.cardHeaderHover}>*/}
      {/*        <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
      {/*          <img src={priceImage1} alt="..." />*/}
      {/*        </a>*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <div className={classes.cardHoverUnder}>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="View"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="transparent" simple justIcon>*/}
      {/*              <ArtTrack className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Edit"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="success" simple justIcon>*/}
      {/*              <Refresh className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Remove"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="danger" simple justIcon>*/}
      {/*              <Edit className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*        </div>*/}
      {/*        <h4 className={classes.cardProductTitle}>*/}
      {/*          <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
      {/*            Brasserie le Cozy*/}
      {/*          </a>*/}
      {/*        </h4>*/}
      {/*        <p className={classes.cardProductDesciprion}>*/}
      {/*          Poulet / frite à volonté, ambiance parisienne. Sauce tomate cuisinée*/}
      {/*          avec le jus des moules.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter product>*/}
      {/*        <div className={classes.price}>*/}
      {/*          <h4>29€ menu open</h4>*/}
      {/*        </div>*/}
      {/*        <div className={`${classes.stats} ${classes.productStats}`}>*/}
      {/*          <Place /> Paris*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card product className={classes.cardHover}>*/}
      {/*      <CardHeader image className={classes.cardHeaderHover}>*/}
      {/*        <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
      {/*          <img src={priceImage2} alt="..." />*/}
      {/*        </a>*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <div className={classes.cardHoverUnder}>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="View"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="transparent" simple justIcon>*/}
      {/*              <ArtTrack className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Edit"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="success" simple justIcon>*/}
      {/*              <Refresh className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Remove"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="danger" simple justIcon>*/}
      {/*              <Edit className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*        </div>*/}
      {/*        <h4 className={classes.cardProductTitle}>*/}
      {/*          <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
      {/*            Repas à domicile / bureau*/}
      {/*          </a>*/}
      {/*        </h4>*/}
      {/*        <p className={classes.cardProductDesciprion}>*/}
      {/*          Nous vous livrons pour partager avec vos amis l aperitif a litalienne*/}
      {/*          accompagné de planches de dégustation.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter product>*/}
      {/*        <div className={classes.price}>*/}
      {/*          <h4>14€ livraison comprise</h4>*/}
      {/*        </div>*/}
      {/*        <div className={`${classes.stats} ${classes.productStats}`}>*/}
      {/*          <Place /> Nice, France*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card product className={classes.cardHover}>*/}
      {/*      <CardHeader image className={classes.cardHeaderHover}>*/}
      {/*        <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
      {/*          <img src={priceImage3} alt="..." />*/}
      {/*        </a>*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <div className={classes.cardHoverUnder}>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="View"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="transparent" simple justIcon>*/}
      {/*              <ArtTrack className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Edit"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="success" simple justIcon>*/}
      {/*              <Refresh className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*          <Tooltip*/}
      {/*            id="tooltip-top"*/}
      {/*            title="Remove"*/}
      {/*            placement="bottom"*/}
      {/*            classes={{ tooltip: classes.tooltip }}*/}
      {/*          >*/}
      {/*            <Button color="danger" simple justIcon>*/}
      {/*              <Edit className={classes.underChartIcons} />*/}
      {/*            </Button>*/}
      {/*          </Tooltip>*/}
      {/*        </div>*/}
      {/*        <h4 className={classes.cardProductTitle}>*/}
      {/*          <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
      {/*            Château d&apos;époque*/}
      {/*          </a>*/}
      {/*        </h4>*/}
      {/*        <p className={classes.cardProductDesciprion}>*/}
      {/*          Château palace. Une escapade simple finalement pas très loin.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter product>*/}
      {/*        <div className={classes.price}>*/}
      {/*          <h4>459€/nuit</h4>*/}
      {/*        </div>*/}
      {/*        <div className={`${classes.stats} ${classes.productStats}`}>*/}
      {/*          <Place /> Transylvanie*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*</GridContainer>*/}
    </div>
  )
}
