import React, { useContext, useState } from "react"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js"
import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js"
import { makeStyles } from "@material-ui/core/styles"
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle"
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"
import InputAdornment from "@material-ui/core/InputAdornment"
import Icon from "@material-ui/core/Icon"
import { changePassword } from "../../api/userAction"
import authContext from "../../context/AuthContext"

const localStyles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
}

const disabledStyle = {
  disabledBack: {
    backgroundColor: "red",
  },
}
const useDisabledStyle = makeStyles(disabledStyle)
const useButtonStyles = makeStyles(buttonStyles)
const useLocalStyle = makeStyles(localStyles)
const useStyles = makeStyles(styles)
const useStylesExtended = makeStyles(stylesExtended)

const ChangePasswordModal = () => {
  const { mpEmail } = useContext(authContext)
  const [modalVisible, setModalVisible] = useState(false)
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState("")
  // Style construction (multi theme options...)
  const buttonClasses = useButtonStyles()
  const classes = useStyles()
  const classesExtended = useStylesExtended()
  const classesLocal = useLocalStyle()
  const disabled = useDisabledStyle()

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      setError("Les nouveaux mots de passe ne correspondent pas.")
      return
    }
    if (!currentPassword) {
      setError("Le mot de passe actuel ne peut pas être vide.")
      return
    }
    if (!newPassword) {
      setError("Le nouveau mot de passe ne peut pas être vide.")
      return
    }

    // TODO: Ajouter l'appel API pour modifier le mot de passe
    console.log("Mot de passe changé")
    changePassword().then((r) => {})

    setModalVisible(false)
    setCurrentPassword("")
    setNewPassword("")
    setConfirmPassword("")
    setError("")
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <Button
        color="danger"
        round
        type={"button"}
        onClick={() => setModalVisible(true)}
      >
        Modifier le mot de passe
      </Button>
      {modalVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
              width: "500px",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Modifier votre mot de passe</h2>
            {mpEmail && <h5 style={{ textAlign: "center" }}>({mpEmail})</h5>}
            <div
              style={{ padding: "20px", display: "flex", flexDirection: "column" }}
            >
              <CustomInput
                type="password"
                secureTextEntry
                labelText="Mot de passe actuel"
                value={currentPassword}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "off",
                  onChange: (e) => {
                    console.log("changing password", e.currentTarget.value)
                    setCurrentPassword(e.target.value)
                  },
                }}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
              />
              <CustomInput
                type="password"
                labelText="Nouveau mot de passe"
                value={newPassword}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "off",
                  onChange: (e) => setNewPassword(e.target.value),
                }}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
              />
              <CustomInput
                type="password"
                labelText="Confirmer le nouveau mot de passe"
                value={confirmPassword}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "off",
                  onChange: (e) => setConfirmPassword(e.target.value),
                }}
                style={{ display: "block", width: "100%", marginBottom: "10px" }}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <div
              style={{ padding: "20px", display: "flex", justifyContent: "center" }}
            >
              <Button
                type="button"
                onClick={handleChangePassword}
                style={{ marginRight: "10px" }}
              >
                Confirmer
              </Button>
              <Button type="button" onClick={() => setModalVisible(false)}>
                Annuler
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChangePasswordModal
