import axios from "axios"
import config from "../config"

const BASE_URL = config.BASE_URL
const apiX = axios.create({
  baseURL: BASE_URL,
})

/**
 * Note : Check login in symfony API and get the TOKEN from JWT
 * @returns {Promise<void>}
 */
export const userLogin = (_credential) => {
  let email = _credential.email
  let forcedMpid = null

  if (email.includes("|")) {
    let startPipe = email.indexOf("|")
    forcedMpid = email.substring(startPipe + 1)
    email = email.substring(0, startPipe)
  }

  const param = {
    username: email,
    password: _credential.password,
    forcedMpid: forcedMpid,
  }
  return apiX.post(BASE_URL + `api/users/multiprologin`, param)
}

/**
 * Note :  allow access to specific zone
 */
export const grantSupremUser = (_mpid, _ps) => {
  const param = {
    mpid: _mpid,
    ps: _ps,
  }

  return apiX.post(BASE_URL + `api/multipro/grantsupremaccess`, param)
}

/**
 * Note :  Change password with confirmation of previous one
 */
export const changePassword = (_email, _ps, _nPs) => {
  const param = {
    email: _email,
    oldPassword: _ps,
    newPassword: _nPs,
  }

  return apiX.post(BASE_URL + `api/multiprouser/updateps`, param)
}
