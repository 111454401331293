import axios from "axios"
import config from "../config"
import moment from "moment"
import { parseBool } from "../utils/functions"

const BASE_URL = config.BASE_URL
const apiX = axios.create({
  baseURL: BASE_URL,
})

/**
 * Note : return a list of events of the specified mpid
 *      ->mpid : multipro account id
 *      ->running : to filter event (0,1,2 -> past event, active event, all event)
 *      ->returnType : List all event or Count them ( 'list',  'count')
 * @param _mpId
 * @param _running
 * @param _groupBySameEvent
 * @returns {Promise<AxiosResponse<any>>}
 */
export const eventList = (
  _mpId,
  _running = 2,
  _groupBySameEvent = 0,
  _draft = 0,
  _sortOption = "az",
  _last7 = 0
) => {
  const param = {
    mpId: _mpId,
    running: _running,
    groubBySameEvent: _groupBySameEvent,
    showDraft: _draft,
    sortOption: _sortOption,
    returnType: "list",
    last7: _last7,
  }
  console.log(param)

  return apiX.post(BASE_URL + `api/multipro/event`, param)
  // return apiX.post(BASE_URL + `api/multipro/event`, param, {
  //   headers: { "Access-Control-Allow-Origin": "*" },
  // })
}

/**
 * Note : Return the number of 'event'
 *      ->mpid : multipro account id
 *      ->running : to filter event (0,1,null -> past event, active event, all event)
 *      ->returnType : List all event or Count them ( 'list',  'count')
 * @param _mpid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const eventCount = (_mpid) => {
  const param = {
    mpid: _mpid,
    running: 1,
    returnType: "count",
  }

  return apiX.post(BASE_URL + `api/multipro/event`, param)
}

/**
 * Note : Advanced Statistics on last days events
 * @param _mpid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const eventLastDays = (_mpid) => {
  const param = {
    mpid: _mpid,
    running: 1,
    returnType: "count",
  }

  return apiX.post(BASE_URL + `api/multipro/getlastdays`, param)
}

/**
 * Note : Advanced Statistics on last days events
 * @param _mpid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const eventLastMonths = (_mpid) => {
  const param = {
    mpid: _mpid,
    running: 1,
    returnType: "count",
  }

  return apiX.post(BASE_URL + `api/multipro/getlastmonths`, param)
}

/**
 * Note : Advanced Statistics on last days events
 * @param _mpid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const runningEventByCompany = (_mpid) => {
  const param = {
    mpid: _mpid,
  }

  return apiX.post(BASE_URL + `api/multipro/eventbycompany`, param)
}

/**
 * Note : Return the details of an 'event'
 * @returns {Promise<AxiosResponse<any>>}
 * @param _evId
 */
export const eventDetails = (_evId) => {
  return apiX.get("api/events/fullone/" + _evId)
}

/**
 * Upload an event with data formatting and image upload if necessary
 * 20/07/2021 Adding draft option
 * @param _data
 * @param _cid
 * @param _newImagePath
 * @param _isNewImage
 * @param _draft
 * @param _mpId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateEvent = async (
  _data,
  _cid,
  _newImagePath,
  _isNewImage,
  _draft = false,
  _mpId
) => {
  console.log("update event", _isNewImage, _data)
  if (_isNewImage) {
    let data = new FormData()
    data.append("id", _data.ev_id)
    data.append("image", _newImagePath)

    const dataToUpload = {
      id: parseInt(_data.ev_id),
      cid: _cid,
      name: _data.ev_name,
      typeId: parseInt(_data.type_id),
      details: _data.details,
      tarif:
        _data.tarif === undefined || _data.tarif === ""
          ? 0
          : parseFloat(_data.tarif),
      tarif_promo:
        _data.tarif_promo === undefined || _data.tarif_promo === ""
          ? 0
          : parseFloat(_data.tarif_promo),
      isFree: parseBool(_data.is_free),
      dtFrom: moment(_data.dt_from).format("YYYY-MM-DD HH:mm:00"),
      dtTo: moment(_data.dt_to).format("YYYY-MM-DD HH:mm:00"),
      isDraft: _draft,
      cids: _data.cids,
      mpId: _mpId,
      allDay: _data.all_day,
      // with 1 being Monday and 7 being Sunday by default but API waiting 0 base Array ( 0 for monday )
      isoWeekDay: moment.utc(_data.dt_from).isoWeekday() - 1 ?? 0,
    }
    const updateData = axios.post(BASE_URL + `api/events/update`, dataToUpload, {
      headers: {
        "Content-Type": "application/json",
      },
    })

    const updatePic = axios.post(BASE_URL + `api/events/updatepicrn`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    return axios.all([updateData, updatePic]).then(
      axios.spread((...response) => {
        console.log("multi spread axios", response)
      })
    )
  } else {
    const dataToUpload = {
      id: parseInt(_data.ev_id),
      cid: _cid,
      name: _data.ev_name,
      typeId: parseInt(_data.type_id),
      details: _data.details,
      tarif:
        _data.tarif === undefined || _data.tarif === ""
          ? 0
          : parseFloat(_data.tarif),
      tarif_promo:
        _data.tarif_promo === undefined || _data.tarif_promo === ""
          ? 0
          : parseFloat(_data.tarif_promo),
      isFree: parseBool(_data.is_free),
      dtFrom: moment(_data.dt_from).format("YYYY-MM-DD HH:mm:00"),
      dtTo: moment(_data.dt_to).format("YYYY-MM-DD HH:mm:00"),
      isDraft: _draft,
      needToSaveImage: _data.needToSaveImage ?? false,
      cids: _data.cids,
      originalEventId: null,
      mpId: _mpId,
      allDay: _data.all_day,
      // with 1 being Monday and 7 being Sunday by default but API waiting 0 base Array ( 0 for monday )
      isoWeekDay: moment.utc(_data.dt_from).isoWeekday() - 1 ?? 0,
    }
    console.log("before update:", dataToUpload)

    return await apiX.post(`api/events/updatepro`, dataToUpload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  }
}

/**
 * Adding a new event
 * 20/07/2021 Adding draft option
 * Insertion d'un nouvel événement
 * @returns {Promise<void>}
 * @param _data
 * @param _newImagePath
 * @param _draft
 * @param _mpId
 */
export const uploadNewEvent = async (
  _data,
  _newImagePath,
  _draft = false,
  _mpId
) => {
  // Sending data
  let data = new FormData()
  data.append("image", _newImagePath)
  data.append("name", _data.ev_name)
  data.append("typeId", parseInt(data.type_id))
  data.append("details", _data.details)
  data.append(
    "tarif",
    _data.tarif === undefined || _data.tarif === "" ? 0 : parseFloat(_data.tarif)
  )
  data.append(
    "tarif_promo",
    _data.tarif_promo === undefined || _data.tarif_promo === ""
      ? 0
      : parseFloat(_data.tarif_promo)
  )
  data.append("isFree", parseBool(_data.is_free))
  data.append("dtFrom", moment(_data.dt_from).format("YYYY-MM-DD HH:mm:00"))
  data.append("dtTo", moment(_data.dt_to).format("YYYY-MM-DD HH:mm:00"))
  data.append("draft", _draft)
  data.append("cids", _data.cids)
  data.append("needToSaveImage", _data.needToSaveImage)
  data.append("mpId", _mpId)
  data.append("allDay", _data.all_day)
  // with 1 being Monday and 7 being Sunday by default but API waiting 0 base Array ( 0 for monday )
  data.append("isoWeekDay", moment.utc(_data.dt_from).isoWeekday() - 1 ?? 1)

  return await apiX.post(`api/events/newmpmulti`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}

/**
 *
 * @param _mpId
 * @param _day
 */
export const openClosedCompanyListByMultiproId = (_mpId, _day) => {
  // console.log("action", _mpId, _day)
  return apiX.get("api/events/companylistoc/" + _mpId + "/" + _day)
}

export const notificationByEmailOnEventActionByMultipro = (_data, _mpId) => {
  let data = new FormData()
  data.append("name", _data.ev_name)
  data.append("details", _data.details)
  data.append("cids", _data.cids)
  data.append("mpId", _mpId)

  return apiX.post(`api/events/mailingmultiproevent`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}

/**
 *
 * @param id
 * @returns {Promise<string|void>}
 */
export const removeEvents = async (id) => {
  return await apiX
    .delete("api/events/remove", {
      data: { id: parseInt(id, 10) },
    })
    .then((response) => "ok")
    .catch((err) => console.log("lose " + err))
}
