import axios from "axios"
import config from "../config"
import { getItem } from "../services/LocaleStorage"
import { isArray, isObject } from "underscore"

const BASE_URL = config.BASE_URL
const apiX = axios.create({
  baseURL: BASE_URL,
})

/**
 * Note : return a list of events of the specified mpid
 *         * Return :
 *        - 0 ( Company not found )
 *        - 1 ( ok )
 *        - 2 ( multipro )
 *        - 3 ( Missing parameter(s).)
 * @param _mpid
 * @param _filter
 * @returns {Promise<AxiosResponse<any>>}
 */
export const companyList = (_mpid, _filter = false) => {
  const param = {
    mpid: _mpid,
    filter: _filter,
  }

  return apiX.post(BASE_URL + `api/multipro/companylist`, param)
}

/**
 * Note :  In base of subscription company
 * Return company that have a publication in the event table we need to check all others companies
 * @param _mpid
 * @param _filter
 * @returns {Promise<AxiosResponse<any>>}
 */
export const companyListThatCanPublish = (_mpId) => {
  return apiX.get(BASE_URL + `api/multipro/canbe/` + _mpId)
}

/**
 * Note : Return the details of a 'company'
 * @returns {Promise<AxiosResponse<any>>}
 * @param _cid
 */
export const companyDetails = (_cid) => {
  return apiX.get("api/users/retrieve/" + _cid)
}

/**
 * Note : Retrieve the current list of business type set in the database
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getBusinessType = () => {
  return apiX.get("api/businessType/list")
}

/**
 * Note : Uodate User AND company data (image is updated in another procedure
 * @param _data
 * @param _cid
 * @returns {Promise<TResult1>}
 */
export const updateUserCompany = async (_data, _cid) => {
  const dataUser = {
    id: parseInt(_cid),
    firstname: _data.firstName,
    lastname: _data.lastName,
    email: _data.email,
    password: _data.password,
    valid_conditions: 1,
  }
  const dataCompany = {
    id: parseInt(_cid),
    name: _data.companyName,
    detail: _data.detail,
    address: _data.address,
    city: _data.city,
    cp: _data.cp,
    country: _data.country,
    latitude: _data.latitude,
    longitude: _data.longitude,
    phone: _data.phone,
    admin: _data.admin,
    website: _data.website,
    businessType: _data.businessType,
    workingHours: _data.workingHours,
  }

  const updateUser = axios.post(BASE_URL + `api/users/updateuser`, dataUser)
  const updateCompany = axios.post(BASE_URL + `api/users/updatecompany`, dataCompany)
  return axios.all([updateUser, updateCompany]).then(
    axios.spread((...response) => {
      console.log("multi spread axios")
    })
  )
}

/**
 * Note update new image or set fist image company (image is facultative bur recommended)
 * @param _cid
 * @param _image
 * @returns {Promise<AxiosResponse<any>>}
 */
export const uploadImageCompany = (_cid, _image) => {
  let data = new FormData()
  data.append("image", _image)
  data.append("cid", _cid)

  return apiX.post(BASE_URL + `api/company/setimage`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

/**
 * Note : Create a new company allow a small creation with only few parameter
 * Min input parameter :  (email, company name and mpid [multipro ID] )
 * todo pass businesstype
 * @param _data
 * @param _mpid
 */
export const createCompany = (_data, _mpId) => {
  const param = {
    mpid: _mpId,
    cname: _data.companyName,
    email: _data.email,
    lastName: _data.lastName,
    detail: _data.detail,
    website: _data.website,
    phone: _data.phone,
    businessType: _data.businessType,
    address: _data.address,
    latitude: _data.latitude,
    longitude: _data.longitude,
    city: _data.city ?? null,
    cp: _data.cp ?? null,
    country: _data.country ?? null,
    workingHours: _data.workingHours ?? null,
  }

  return apiX.post(BASE_URL + `api/users/multiproregister`, param)
}

/**
 *
 * @param _data
 * @param _mpId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const massCompanyCreation = (_data, _mpId) => {
  // feed array
  let data = []
  if (isArray(_data) || isObject(_data)) {
    _data.map((item) => {
      data.push({
        mpid: _mpId,
        cname: item.companyName,
        email: item.email,
        lastName: item.lastName,
        detail: item.detail,
        website: item.website,
        phone: item.phone,
        businessType: item.businessType,
        address: item.address,
        latitude: item.latitude ?? 0,
        longitude: item.longitude ?? 0,
        city: item.city ?? null,
        cp: item.cp ?? null,
        country: item.country ?? null,
        workingHours: item.workingHours ?? null,
      })
    })
  }
  // workingHours: JSON.stringify(item.workingHours) ?? null,
  console.log("before data insert :", data)
  return apiX.post(BASE_URL + `api/users/massinsert`, data)
}

/**
 * Note : Count of managed Company
 * @returns {Promise<AxiosResponse<any>>}
 * @param _cid
 */
export const companyCount = (_mpid) => {
  const param = {
    mpid: _mpid,
  }

  return apiX.post(BASE_URL + `api/multipro/companycount`, param)
}

// /**
//  * Note : Count of managed Company
//  * @returns {Promise<AxiosResponse<any>>}
//  * @param _cid
//  */
// export const eventCount = (_mpid) => {
//   const param = {
//     mpid: _mpid,
//     running: 1,
//     returnType: "count",
//   }
//
//   return apiX.post(BASE_URL + `api/multipro/event`, param, {
//     headers: { "Access-Control-Allow-Origin": "*" },
//   })
// }

/**
 * Note : Count of managed Company
 * @returns {Promise<AxiosResponse<any>>}
 * @param _mpid
 * @param _connectedUserEmail
 */
export const getMultiproAccountData = (_mpid, _connectedUserEmail = null) => {
  const param = {
    mpid: _mpid,
    connectedUserEmail: _connectedUserEmail,
  }
  return apiX.post(BASE_URL + `api/multipro/account`, param)
}

/**
 * Note : Count of managed Company
 * @returns {Promise<AxiosResponse<any>>}
 * @param _data
 * @param _mpid
 * @param _newImagePath
 */
export const setMultiproAccountData = (_data, _mpid, _newImagePath = null) => {
  // const param = {
  //   mpid: _mpid,
  //   name: _data.name,
  //   email: _data.email,
  //   phone: _data.phone,
  //   defaultSubCompanyPassword: _data.defaultSubCompanyPassword,
  // }

  let formData = new FormData()
  formData.append("image", _newImagePath ?? null)
  formData.append("mpid", _mpid)
  formData.append("name", _data.name)
  formData.append("email", _data.email)
  formData.append("phone", _data.phone)
  formData.append("emailSubCompanyEvent", _data.emailSubCompanyEvent)
  formData.append("defaultSubCompanyPassword", _data.defaultSubCompanyPassword)
  formData.append(
    "defaultSubCompanyWorkingHours",
    _data.defaultSubCompanyWorkingHours
  )
  return apiX.post(BASE_URL + `api/multipro/save`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  })
}

/**
 * Note : remove Company and user (same api call than Remove from app)
 *
 */
export const removeCompanyAndUser = (_cid) => {
  let data = new FormData()
  data.append("cid", _cid)
  const token = getItem("jnlock")

  return apiX.post(BASE_URL + `api/users/delete`, data)
}

/**
 * Note : remove Company and user (same api call than Remove from app)
 *
 */
export const suspendCompany = (_cid) => {
  const param = {
    cid: _cid,
  }
  const token = getItem("jnlock")
  return apiX.post(BASE_URL + `api/multipro/suspend/company`, param)
}
