import jwtDecode from "jwt-decode"
import { getItem, addItem, removeItem } from "./LocaleStorage"
import { userLogin } from "../api/userAction"

export function hasAuthenticated() {
  const token = getItem("jnlock")
  // const result = token && true
  const result = token ? tokenIsValid(token) : false

  if (false === result) removeItem("jnlock")

  return result
}

export function login(_credentials) {
  return userLogin(_credentials)
    .then((response) => {
      return response.data.token
    })
    .then((token) => {
      addItem("jnlock", token)
      const { mpid: mpid } = jwtDecode(token)
      // Adding spécific storage for mp name
      const { name: name } = jwtDecode(token)
      addItem("mpname", name)
      const { roles: roles } = jwtDecode(token)
      addItem("tmpR", roles)
      addItem("role", roles)
      let roleString = roles[0]
      return { mpId: mpid, role: roleString }
      //return mpid
    })
    .catch((e) => {
      removeItem("mpname")
      removeItem("jnlock")
      removeItem("tmpR")
      removeItem("role")
      return false
    })
}

export function logout() {
  removeItem("filter")
  removeItem("jnlock")
  removeItem("mpname")
  removeItem("tmpR")
  removeItem("role")
}

function tokenIsValid(token) {
  const { exp: expiration } = jwtDecode(token)

  if (expiration * 1000 > new Date().getTime()) {
    return true
  }

  return false
}

/**
 * Note retrieve mpId if token is set in local storage if not security logout
 */
export function getMpIdFromLocalStorage() {
  const token = getItem("jnlock")
  if (token) {
    const { mpid: mpid } = jwtDecode(token)
    return mpid
  } else return undefined
}

/**
 * Note retrieve mpId if token is set in local storage if not security logout
 */
export function getMpEmailFromLocalStorage() {
  const token = getItem("jnlock")
  if (token) {
    const { email: email } = jwtDecode(token)
    return email
  } else return undefined
}

/**
 * Note retrieve role if token is set in local storage if not security logout
 */
export function getMpRoleFromLocalStorage() {
  const token = getItem("jnlock")
  if (token) {
    const { roles: roles } = jwtDecode(token)
    return roles[0]
  } else return "user"
}

/**
 * Note retrieve mpId if token is set in local storage if not security logout
 */
export function getMPNameFromLocalStorage() {
  return getItem("mpname")
}

export function setMPNameFromLocalStorage(_name) {
  window.localStorage.setItem("mpname", _name)
}
