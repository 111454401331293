import React, { useContext, useEffect, useState } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Icon from "@material-ui/core/Icon"

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home"
import Business from "@material-ui/icons/Business"
import AccountBalance from "@material-ui/icons/AccountBalance"

// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js"
import { getActivePlan, getPlans } from "../../api/billingAction"

// Context & API
import authContext from "../../context/AuthContext"
import FormControl from "@material-ui/core/FormControl"
import CustomInput from "../../components/CustomInput/CustomInput"
import isNumeric from "validator/es/lib/isNumeric"
import StripeContainer from "./Stripe"
import { Link } from "@chakra-ui/react"
import jwtDecode from "jwt-decode"
import { getItem } from "../../services/LocaleStorage"
import SweetAlert from "react-bootstrap-sweetalert"
import { grantSupremUser } from "../../api/userAction"

const useStyles = makeStyles(styles)

const plansData = [
  {
    id: 1,
    name: "Weekly Free",
    price: "Gratuit",
    info: "1 / semaine",
    phrase: "Entre nous, Vous allez en vouloir plus!",
    icon: "accessibility",
  },
  {
    id: 2,
    name: "Starter",
    price: "€10",
    info: "1 / jour",
    phrase: "Pour débuter sereinement. Évolutif et sans engagement.",
    icon: "weekend",
  },
  {
    id: 3,
    name: "Standard",
    price: "€15",
    info: "2 / jour",
    phrase: "Le classique, convient à de nombreuses situations",
    icon: "home",
  },
  {
    id: 4,
    name: "Booster",
    price: "€20",
    info: "3 / jour",
    phrase: "Pour un business très actif. Pour des résultats rapides !",
    icon: "business",
  },
  {
    id: 5,
    name: "Ultimate",
    price: "€30",
    info: "4 / jour",
    phrase: "Toutes les chances sont de votre coté.",
    icon: "balance",
  },
]

const label = {
  formTitle: "Détails du nouvel événement",
  companyCountLAbel: "Vous gérez actuellement ",
  company: "établissement",
  modifyLimit: "modifier le nombre d'établissement que je peux gérer :",
  warningQuantity:
    "Attention, vous devez précisez le nombre d'établissments pour lesquels vous allez souscrire un abonnement à l'étape suivante\n Choisissez d'abord votre abonnement en fonction de vos besoins.",
  alertTitle: "Autorisation administrateur requise",
  alertPsMandatory: "Vous devez saisir le mot de passe!",
}

export default function PricingPage() {
  const { mpId } = useContext(authContext)
  const classes = useStyles()
  // Active plan id
  const [activePlan, setActivePlan] = useState(1)
  const [plans, setPlans] = useState(undefined)
  // Set to minim managing by default
  const [companyLimit, setCompanyLimit] = useState(1)
  const [prices, setPrices] = useState([])
  const [subscriptionData, setSubscriptionData] = useState(null)
  const [alert, setAlert] = useState(false)
  const [allow, setAllow] = useState(false)
  const token = getItem("jnlock")
  const [mpEmail, setMpEmail] = useState(undefined)

  // useEffect(() => {
  //   const fetchPrices = async () => {
  //     const { prices } = await fetch("/config").then((r) => r.json())
  //     setPrices(prices)
  //   }
  //   fetchPrices()
  // }, [])
  //
  // const createSubscription = async (priceId) => {
  //   const { subscriptionId, clientSecret } = await fetch("/create-subscription", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       priceId,
  //     }),
  //   }).then((r) => r.json())
  //
  //   setSubscriptionData({ subscriptionId, clientSecret })
  // }

  useEffect(() => {
    const { email: email } = jwtDecode(token)
    setMpEmail(email)
    console.log("get Email", email)
  }, [token])

  /**
   * Note : Retrieving plans info and details
   */
  // useEffect(() => {
  //   getPlans()
  //     .then((r) => {
  //       // status === 1 === ok
  //       if (r.data.status === 1) {
  //         setPlans(r.data.data)
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }, [])

  /**
   * Note : active plan retrieving
   */
  useEffect(() => {
    getActivePlan(mpId)
      .then((r) => {
        if (r.data.status === 1) {
          setActivePlan(r.data.data.premium)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [mpId])

  const togglePlan = (_newPlanId) => {
    setActivePlan(_newPlanId)
  }

  const handleInputChange = (_value) => {
    if (_value && isNumeric(_value) && _value > 0) setCompanyLimit(_value)
  }

  /**
   * Note: Design for plan
   */
  const RenderPlan = () => {
    if (plans && activePlan) {
      return plans.map((item, index) => {
        return (
          <GridItem key={index} xs={12} sm={12} md={2}>
            <Card
              pricing
              plain={item.id !== activePlan}
              raised={item.id === activePlan}
            >
              <CardBody pricing plain>
                <h6 className={classes.cardCategory}>{item.name}</h6>
                <div className={classes.icon}>
                  <Icon className={classes.iconBlue}>{item.icon}</Icon>
                </div>
                <p className={classes.cardCategory}>{item.info}</p>
                <h3 className={`${classes.cardTitleBlue} ${classes.marginTop30}`}>
                  {item.id === 1 ? "GRATUIT" : "€" + item.price}
                </h3>
                <p className={classes.cardCategory}>{item.phrase}</p>
                <Button
                  round
                  color={item.id === activePlan ? "rose" : "white"}
                  onClick={() => togglePlan(item.id)}
                >
                  Choisir
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        )
      })
    } else console.log("no data to print")
  }

  /**
   * Note:
   */
  const onConfirm = async (_ps) => {
    // Check super user
    grantSupremUser(mpId, _ps)
      .then((r) => {
        if (r.data.status === 1) {
          hideAlert()
          setAllow(true)
        } else window.alert("accès refusé")
      })
      .catch((e) => window.alert(e))
  }

  /**
   * Note :
   */
  const onCancel = () => {
    hideAlert()
  }

  const hideAlert = () => {
    setAlert(false)
  }

  return (
    <div>
      <GridContainer justify="center" direction="column" alignItems="center">
        {alert && (
          <SweetAlert
            input
            required
            inputType="password"
            title={label.alertTitle}
            validationMsg={label.alertPsMandatory}
            confirmBtnBsStyle={"secondary"}
            onConfirm={(r) => onConfirm(r)}
            onCancel={onCancel}
          >
            Saisissez à nouveau votre mot de passe
          </SweetAlert>
        )}
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>
            Choisissez la solution qui vous correspond
          </h2>
          <h5 className={classes.description}>{label.warningQuantity}</h5>
          <h4 className={classes.companyLimit}>
            {label.companyCountLAbel} {companyLimit} {label.company}
          </h4>
        </GridItem>
      </GridContainer>

      <div
        onClick={() => {
          setAlert(true)
        }}
      >
        <GridContainer
          justify="center"
          alignItems="center"
          className={allow === false && classes.containerDisabled}
        >
          <GridItem xs={12}>
            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
            <stripe-pricing-table
              pricing-table-id="prctbl_1N0g9lFvHGc7tQKBb5Fbmrex"
              publishable-key="pk_test_51LVWLGFvHGc7tQKBy2xXbv9kqx2MfhGtspBpsaGTxtvuxUgmYrb8HjEiRBGIYV85FHkS8wxsqnETEuwLR5AdwsyC00d8zLKKTe"
              client-reference-id={mpId.toString()}
              customer-email={mpEmail}
            ></stripe-pricing-table>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
